import forEach from "../helpers/forEach";
export default function letterAnchors() {
  var letterLinksWrapper = document.getElementsByClassName("js_letter-links")[0];
  var letterLinks = letterLinksWrapper === null || letterLinksWrapper === void 0 ? void 0 : letterLinksWrapper.getElementsByTagName("a");
  if (!(letterLinks !== null && letterLinks !== void 0 && letterLinks.length)) return;
  forEach(letterLinks, letterEventListener);
}
function letterEventListener(letter) {
  if (!letter.id) return;
  letter.addEventListener("click", function () {
    var allTags = document.getElementsByClassName("js_all-tags")[0];
    var target = allTags === null || allTags === void 0 ? void 0 : allTags.getElementsByClassName(letter.id)[0];
    target === null || target === void 0 ? void 0 : target.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  });
}